                                        import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare let $: any;

@Component({
  selector: 'app-add-item1',
  templateUrl: './add-item1.component.html',
  styleUrls: ['./add-item1.component.scss']
})
export class AddItem1Component implements OnInit {

  add:any={};
  public btndisable:boolean=false;
  constructor(public appservice:AppService,private toastr: ToastrService, private http: HttpClient) {

  
    if(this.appservice.isEdit)
    {
      this.add=appservice.Edit_Row;
    }
    else
    {
      this.clear();
      this.add.ID="0";

    }
    this.server=appservice.Server_URL+"Api/Master/UploadItemImage?ID="+this.add.ID+"&Company="+appservice.Company+"&Name="+this.add.Item_Name+"&Description="+this.add.Description;
  

   }
  
   clear()
   {
     
    // this.appservice.get_fields_of('Item_Master').forEach((activity) => {
    //   if(activity.Default_Value=="T_Date")
    //   {
    //     this.add[activity.Field]=this.appservice.T_Date;
    //   }
    //   else  if(activity.Default_Value=="code_auto")
    //   {
    //     this.Auto_Code=true;

    //   }
    //   else{
    //   this.add[activity.Field]=activity.Default_Value;
    //   }
    // });

    if(this.Auto_Code)
    {
      this.get_item_Code()
    }
   }

   get_item_Code()
   {
    
      if(this.appservice.isEdit==false)
      {
      this.appservice.getc("Api/Master/get_Item_Code").subscribe((res: any) => { 
        this.add.Item_Code=res;
          
      }); 
    }
      
   }

   Auto_Code:boolean=false;
  
   uploadedFiles: any[] = [];

   server;
   onUpload(event) {
       for(let file of event.files) {
           this.uploadedFiles.push(file);
       }
 
       this.toastr.success("File Uploaded  Success", 'Msg');
       
   }
 
   addData(f)
   {
    
      this.add.Company=this.appservice.Company;
      this.add.Company_ID=this.appservice.Company;
      this.add.Created_by=this.appservice.CREATED_BY;
      this.add.Table_Name="Item_Master";
      if (f.invalid === false)
      {
  
      this.btndisable=true;
            this.http.post(this.appservice.Server_URL + 'api/Master/Post_Data', this.add, { headers: this.appservice.headers })
              .subscribe(
                (val: string) => {
                  this.btndisable=false;

                  if(val=="True")
                  { this.toastr.success("Details Salved Success", 'Msg');

                  if(this.appservice.isEdit)
                  {
                    this.appservice.back();

                  }
                  else
                  {
                  this.appservice.isEdit=false;
                  //this.appservice.get_Item_Master();
                  $(".cls_0").focus();
                  f.submitted=false;
                  this.clear();
                  }
                }
                else{
                  this.toastr.error(val, "Error", { timeOut: 3000 });
                }
                },
                response => {
                  this.toastr.error('Error ', response, {
                    timeOut: 3000
                  });
                  
                });
     
    }
   }

  ngOnInit(): void {
  }

}
