import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";
  public Server_URL = "http://localhost:55245/";
  public Quotation_Format="Format1";
  public System_mac_address="Not found"
  public Area_Map: boolean = false;
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public check_Unicod: boolean = false;


  public TCS_Enable: boolean = true;
  public Balance_SMS = "false";


  public key_value="";

  public Intrest_Format="Flat"

  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:55245/";

  public DB_Vadi_display: boolean = false;
  public Print_Button: boolean = false;

  public Ledger_Update:boolean=true;
  public Cust_Amt: boolean = false;
  public Lfrom = "34";
  public Top = 50;

  public Logo_location = "Top_qubha";
  public Logo_Name = "Qubha";
  public Logo_location_Loin = "Chef";
  public Logo_Sub_Name = "Soft";
  public Phone_No = "9597436220";

  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = true;
  public Check_Stock: boolean = false;
  
  public Image_disp: boolean = false;
  public Ledger_Type = "1";

  public Group_Enable: boolean = false;
  public Print_Bill: boolean = false;
  
  public Save_Print: boolean = true;
  public Save_Print_Mobile: boolean = false;
  public Save_New: boolean = false;

  public  Stockbase_Sales: boolean = false;

  public  mobile_menu: boolean = false;

  
  public from_customer_page:boolean=false;
  public W_height = 0;
  public W_width = 0;

  public SM_Row = [];
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;
  
  public Loan_Issue_Page="/collection/emi-loan-issue";
  public Add_Receipt_Page="/transaction/add-receipt-entry-two";
  public U_Rights=""
  public Item_Group_ = "1";
  public isadd_Page:boolean=false;
  lenth: number = 0;
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));

      try {
        this.Customer_Area = localStorage.getItem('Area');
      } catch { }
      this.Company = "_" + this.data.UM_Company;

    }


    this.W_height = window.innerHeight;

    this.W_width = window.innerWidth;

    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");

    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");


    this.exp_Date = this.datePipe.transform(new Date(), "MM/yy");
    this.Pay_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");



    this.Y_Date = this.datePipe.transform(new Date().getDate() - 1, "dd-MMM-yyyy");
    this.Today_Date = Today_Date;


  
    this.S_From_ = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };


    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.load_themes('');



  }

  public lable_ = "";
  public item = "";
  public Filter_Type = "contain";

  public openModal: boolean = false;
  Open_Modal(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }

  Close_Modal() {
    this.openModal = false;
    $('#small_Module').modal('hide');
  }

  public Header_Disp = "";

  public add_item_name = "/add-items1";
  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "";
  public CREATED_BY = "";
  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Edit_Row1: any = {};

  public Selected_Row: any = {};
  public Default_ref_ID: string = "Role";
  public URL_Location = "";

  public disp_category:boolean=false;
  public img_visible: boolean = false;

  
  public Date;
  public Date1;
  public From_Date;
  public To_Date;
  public S_From_ = "";

  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print="false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public S_From = "";
  public S_To = "";
  public FS_Date = "2021-04-01";

  public S_Bill_Type = "All";
  public S_Thari_Status = "All";
  public Calibration_Standard ="";
  public Category = "All";

  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"


  public Y_Date = "";
  public data: any = {};
  public Order_Status = "Pending";
  public Search_User = "All";
  public Approval_Status = "Pending";


  public Customer_Area = "All";
  public item_name_disp = "English";

  public T_Date = "";
  public T_Date_Time = "";

  public T_Status="A";

  public Pay_Date="";
  public exp_Date="";
  public Open_Customer_Total = 0;
  public Open_Customer_Length = 0;
  public DB_Expense_Total=0;
  public Receipt_Row_Total=0;
  public Emi_Loan_Issue_Total=0;
  public DB_Bank_Row=0;
  public DB_Bank_Total=0;

  public Item_Group="";
  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/user-details' },
    { Menu: 'Role Master', Link: '/role-master' },
    { Menu: 'Role Rights', Link: '/role-permissions' }

  ];

  public TimeSheet_Report = [
    { Menu: 'Time Sheet Report', Link: '/report/timesheet-report', class: '' },
    // { Menu: 'Customer Wise', Link: '/transport/tractor-report', class: '' },
    // { Menu: 'Total hrs', Link: '/transport/jcb-report', class: '' },
  ];

  public Enquiry_Report = [
    { Menu: 'Enquiry Report', Link: '/report/enquiry-report', class: '' },
    // { Menu: 'Customer Wise', Link: '/transport/tractor-report', class: '' },
    // { Menu: 'Total hrs', Link: '/transport/jcb-report', class: '' },
  ];

  public Followup_Report = [
    { Menu: 'Follow Up Report', Link: '/report/followup-report', class: '' },
    // { Menu: 'Customer Wise', Link: '/transport/tractor-report', class: '' },
    // { Menu: 'Total hrs', Link: '/transport/jcb-report', class: '' },
  ];
  public Completed_Report = [
    { Menu: 'Completed Follow Up', Link: '/report/completed-report', class: '' },
    // { Menu: 'Customer Wise', Link: '/transport/tractor-report', class: '' },
    // { Menu: 'Total hrs', Link: '/transport/jcb-report', class: '' },
  ];

  
  
  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];
  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public datetimefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-ddTHH:mm");
  }
  

  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_ID = "";
  public Emp_ID = "";
 

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

  F_Row = [];
  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  Reference_Rows = [];
  get_Reference() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
    });
  }

  Product_Rows = [];
  public isEdit: boolean = false;
  public load_from_make_invoice: boolean = false;



  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }


  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Current_User: any = {};

  public Order_No = "0";
  public Menus_Rows: any = {};
  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {
    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;
      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  get_ref(data) {

    
    try{
    return this.Reference_Rows.filter(e => e.Ref_ID == data);
    }catch{

      return [];
    }
  }
  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.Menu_Master_Rows = JSON.parse(res).record;

      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");



    });
  }


  loading = false;


  Num(data) {
    return Number(data).toFixed(2);
  }



  public filter_data="";
  pdfType = "Open";


  get(url) {
    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });

//debugger
    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public load_page(data) {
    //debugger
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }


  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";
  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }


  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];
  public Variable_Settings_GF = ['ID', 'S_Variable', 'S_Value', 'S_Default'];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }




  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }

  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  public sum_of(dt, field) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0);
      } catch { }
    }

    return len;
  }



  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };
  headers;

  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  //loading = false;

  public Dashboard_Row = [];
  get_Dashboard() {
    this.Dashboard_Row = [];
    this.loading = true;

    this.get("Api/Master/get_dashboard?").subscribe((res: any) => {
      this.Dashboard_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }
  File_Name = "Data";

  export_pdf() {
    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  get_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }

  public User_Details_row=[];

  get_User_Details() {
    this.getc("Api/transaction/get_User_Details").subscribe((res: any) => {
      this.User_Details_row = JSON.parse(res).record;

    });
  }

  public CustomerEntry_Details_GF = [
    { Field: 'cus_name', Name: 'Customer', Align: '' },
    { Field: 'cus_contactno', Name: 'PhoneNo', Align: '' },
    { Field: 'date_', Name: 'Date', Align: '' },
    { Field: 's_type', Name: 'Software Type', Align: '' },
    { Field: 'cus_type', Name: 'Type', Align: '' },
    { Field: 'remarks', Name: 'Remarks', Align: '' },
    { Field: 'created_by', Name: 'Created By', Align: '' },
  ];
  public Enquiry_Entry_Rows = [];
  public Enquiry_Entry_col_GF = ['cus_name', 'cus_contactno', 's_type'];

  public User_ID=0;

  get_Enquiry_Entry() {
    this.isload = true;
    this.get("Api/transaction/get_Enquiry_Entry?From=" + this.S_From + "&To=" + this.S_To +"&User="+this.Search_User).subscribe((res: any) => {
      this.isload = false;
      this.Enquiry_Entry_Rows = JSON.parse(res).record;
    });
  }


  public Customer_Master_Export;
  public Customer_Master_Rows
  public supplier_Order_by
  public Customer_Master_col_GF = ['cus_code','cus_name','cus_city','cus_contactno','cus_gstno'];


  get_Customer_Master() {
      
    this.get("Api/Master/get_Customer_Master?Status=" + this.T_Status).subscribe((res: any) => {
      
      this.Customer_Master_Rows = JSON.parse(res).record;

    });
  }

  public Product_Master_Export;
  public Product_Master_Rows;
  public Product_Master_col_GF = ['pm_type','pm_name','pm_rate'];


  get_Product_Master() {
      
    this.get("Api/Master/get_Product_Master?Status=" + this.T_Status).subscribe((res: any) => {
      
      this.Product_Master_Rows = JSON.parse(res).record;

    });
  }


  public Customer_Search_GF = ['cus_code','cus_name','cus_city','cus_contactno','cus_gstno'];
  public SLedger_Rows = [];
  get_Ledger_Master() {
    this.isload = true;
   
    this.get("Api/Master/get_Customer_Master?Status=A").subscribe((res: any) => {
      this.isload = false;
      this.Customer_Master_Rows = JSON.parse(res).record;
      this.SLedger_Rows = JSON.parse(res).record;
    });
  }

  public Project_Master_Export;
  public Project_Master_Rows;
  public Project_Master_col_GF = ['pm_cus_id'];

  public Project_Search_GF = ['pm_cus_name','pm_projectname'];
  public SProject_Rows = [];
  get_Project_Master() {
    this.isload = true;
   
    this.get("Api/Master/get_Project_Master?Status=A").subscribe((res: any) => {
      this.isload = false;
      //this.Customer_Master_Rows = JSON.parse(res).record;
      this.SProject_Rows = JSON.parse(res).record;
    });
  }

  public Time_Sheet_Export;
  public Time_Sheet_Rows=[];
  public Time_Sheet_col_GF = ['ts_cus_id'];

  get_Time_Sheet_Details() {
    this.isload = true;
    this.Time_Sheet_Rows = [];
    this.get("Api/Transaction/get_Time_Sheet?From=" + this.S_From + "&To=" + this.S_To+"&User="+this.Search_User).subscribe((res: any) => {
      this.Time_Sheet_Rows = JSON.parse(res).record;
      this.isload = false;
    });

  }

  public T_Date_TimeD="";
  public New_Enquiry_count=0;
  public New_Enquiry_Row=[];
  public PNew_Enquiryr_Row=[];
  get_New_Enquiry() {
    this.New_Enquiry_count=0;

    this.get("Api/Transaction/get_New_Enquiry?From="+this.S_From+"&To="+this.S_To+"&User="+this.Search_User).subscribe((res: any) => {
      //debugger
      this.New_Enquiry_Row = JSON.parse(res).record;
      this.PNew_Enquiryr_Row=JSON.parse(res).record;
      try
      {
      this.New_Enquiry_count = this.New_Enquiry_Row.length;
      }catch{}
    });
  }
  public Home_Collection_count=0;
  public Home_Collection_Row=[];
  public PHome_Collection_Row=[];
  public Home_Collection_Emp_ID=0;
  get_Home_Collection() {
    this.Home_Collection_count=0;

    this.get("Api/Master/get_Home_Collection?From="+this.S_From+"&To="+this.S_To+"&Emp_ID="+this.Home_Collection_Emp_ID).subscribe((res: any) => {
      this.Home_Collection_Row = JSON.parse(res).record;
      this.PHome_Collection_Row=JSON.parse(res).record;
      try
      {
      this.Home_Collection_count = this.Home_Collection_Row.length;
      }catch{}

     
     
    });
  }

  public F_Type="All";
  public FollowUp_count=0;
  public FollowUp_Row=[];
  public PNew_FollowUp_Row=[];
  public FollowUp_GF=[];
  get_FollowUp() {
    this.New_Enquiry_count=0;

    this.get("Api/Transaction/get_FollowUp?From="+this.S_From+"&To="+this.S_To+"&User="+this.Search_User+"&Type="+this.F_Type).subscribe((res: any) => {
      this.FollowUp_Row = JSON.parse(res).record;
      this.PNew_FollowUp_Row=JSON.parse(res).record;
      try
      {
      this.FollowUp_count = this.New_Enquiry_Row.length;
      }catch{}
    });
  }

  
  public Completed_FollowUp_Row=[];
  get_Completed_FollowUp() {
    //this.New_Enquiry_count=0;

    this.get("Api/Report/get_Completed_FollowUp?From="+this.S_From+"&To="+this.S_To+"&User="+this.Search_User).subscribe((res: any) => {
      this.Completed_FollowUp_Row = JSON.parse(res).record;
      //this.PNew_FollowUp_Row=JSON.parse(res).record;
      try
      {
      //this.FollowUp_count = this.New_Enquiry_Row.length;
      }catch{}
    });
  }

  get_FollowUp_() {
    this.New_Enquiry_count=0;

    this.get("Api/Transaction/get_FollowUp?From="+this.S_From_+"&To="+this.S_From_+"&User="+this.Search_User+"&Type="+this.F_Type).subscribe((res: any) => {
      this.FollowUp_Row = JSON.parse(res).record;
      this.PNew_FollowUp_Row=JSON.parse(res).record;
      try
      {
      this.FollowUp_count = this.New_Enquiry_Row.length;
      }catch{}
    });
  }


public Employee_Master_Export = [];
public Status="A";
public Employee_order_by=" emp_name asc";
public Employee_Master_Rows = [];
public Employee_Master_col_GF = ['emp_id','emp_code']; 
 
get_Employee_Master() {
this.isload = true;
  this.get("Api/Master/get_Employee_Master?Status="+this.Status+"&Order_by="+this.Employee_order_by).subscribe((res: any) => {
    this.isload = false;
    this.Employee_Master_Rows = JSON.parse(res).record;
  });
}

}
